"use client";
import React, { createContext, ReactNode, useContext, useState } from "react";

type AppContextProps = {
  hasProducts: boolean;
  shoppingCartOpen: boolean;
  showCart: boolean;
  testMode: boolean;
  setShoppingCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHasProducts: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>;
  setTestMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextProps | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === null) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [showCart, setShowCart] = useState(false);
  const [shoppingCartOpen, setShoppingCartOpen] = useState(false);
  const [hasProducts, setHasProducts] = useState(false);
  const [testMode, setTestMode] = useState(false);

  return (
    <AppContext.Provider
      value={{
        hasProducts,
        shoppingCartOpen,
        showCart,
        testMode,
        setShoppingCartOpen,
        setHasProducts,
        setShowCart,
        setTestMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
